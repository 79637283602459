<template>
  <div>
    <vx-card class="hide-on-mobile" style="min-height: 50px;border-radius:8px;"></vx-card>
    <div class="mt-5 tab-wrapper" v-if="fastCheckData.length">
      <vs-tabs class="activity-tab" v-model="activeTab">
        <vs-tab v-for="(item,index) in fastCheckData" :key="index" :label="item.name">
          <div>
            <FastCheckTab
              :data="item"
              :fastCheckId="item._id"
              @changeRating="onChangeRating"
            ></FastCheckTab>
          </div>
        </vs-tab>
      </vs-tabs>

    </div>

  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";
  import FastCheckTab from "./fastCheckTab";

  export default {
    components: {
      vSelect,
      FastCheckTab
    },
    data() {
      return {
        rating: "1",
        fastChecks: {},
        forceRender: 0,
        fastCheckId: '',

        fastCheckDevelopments: {},
        fastCheckData: '',
        fastCheckDevelopmentID: null,

        currentResource: {},
        activeTab: 0
      }
    },
    watch: {
      fastCheckFilter(obj) {
        this.getFastCheckDevelopment();
      },
    },
    methods: {
      ...mapActions("fastCheck", [
        "getCenterAdminFastChecksResources",
        "fetchCenterAdminFastChecks",
        "setRating"
      ]),
      onChangeRating(val = false, rating, id) {
        if (val) {
          this.rating = rating;
          this.fastCheckId = id;
          this.getFastCheckDevelopment();
        }
      },
      getFastCheckDevelopment() {
        let filter = {
          fastCheckId: this.fastCheckId,
          rating: this.rating,
        };
        this.$vs.loading()
        this.getCenterAdminFastChecksResources(filter)
          .then((res) => {
            this.$vs.loading.close()
          })
          .catch(err => {
            this.$vs.loading.close()
          })
      }
    },
    async created() {
      this.setRating(this.rating);
      this.$vs.loading()
      await this.fetchCenterAdminFastChecks()
        .then(async (res) => {
          this.$vs.loading.close();
          this.fastCheckData = await res.data.data;
        })
        .catch(err => {
          this.$vs.loading.close()
        })
    },
  }
</script>

